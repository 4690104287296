import React, { Component } from "react";
import axios from "axios";
import ToggleButton from "./toggle-button"


const url = `https://graph.instagram.com/me/media?fields=id,media_url,permalink,caption&access_token=${process.env.GATSBY_BASIC_DISPLAY_KEY}`;


class GalleryComponent extends Component {
  constructor() {
    super();
    this.state = {
      photos: [],
      address: [url],
      loading: false,
      page: 0,
      prevY: 0,
      photoIndex: 0,
      modalOpen: false,
      infinite: false
    };
    this.toggleInfiniteScroll.bind(this);
  }
  
  getPhotos(page) {
    this.setState({ loading: true });
    axios.get(page)
    .then(res => {
      this.setState({ photos: [...this.state.photos, ...res.data.data]});
      this.setState({ address: [...this.state.address, res.data.paging.next]});
      this.setState({ loading: false });
    });
  }

  componentDidMount() {
    const page = this.state.page;
    this.getPhotos(this.state.address[page]);
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.15
    };
    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );
    this.observer.observe(this.loadingRef);
  }

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;
    if (this.state.prevY > y && this.state.infinite) {
      this.loadMore();
    }
    this.setState({ prevY: y });
  }

  loadMore() {
    const curPage = this.state.page + 1;
    const nextAddr = this.state.address[curPage];
    this.getPhotos(nextAddr);
    this.setState({ page: curPage });
  }

  toggleInfiniteScroll() {
    this.setState((prevState) => {
      return ({infinite: !prevState.infinite});
    })
  }

  render() {
    const loadingCSS = {
      height: "0px",
      margin: "30px"
    };
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    return (
      <div id="gallery">
        <div id="gallery-header-container">
          <h1 className="page-title">Gallery</h1>
          <ToggleButton 
            toggleLabel={"Infinite Scroll"}
            handleToggle={() => this.toggleInfiniteScroll()}
          />
        </div>
        <div className="gallery-grid">
          {this.state.photos.map(gram =>(
            <div className="post-container">
              <a href={gram.permalink} alt={gram.permalink}>
                  <div className="ig-image-container">
                      <img src={gram.media_url} alt={gram.caption}/>
                  </div>
              </a>
            </div>
          ))}
        </div>
        <div ref={loadingRef => (this.loadingRef = loadingRef)} style={loadingCSS}>
          <span style={loadingTextCSS}>Loading...</span>
        </div>
        {!this.state.infinite && 
        <button id="load-button" onClick={() => this.loadMore()}>
          View More
        </button>
        }
      </div>
    ); 
  }
}

export default GalleryComponent;
