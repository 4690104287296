import React from 'react'

const ToggleButton = ({toggleLabel, handleToggle}) => (
  <div id="toggle-button">
    <div id="toggle-label">
      <span>{toggleLabel}</span>
    </div>
    <div id="switch-container">
      <label class="switch">
        <input type="checkbox" onChange={handleToggle} />
        <span class="slider round"></span>
      </label>
    </div>
  </div>
)

export default ToggleButton