import React from "react"
import SEO from "../components/seo"
import GalleryComponent from "../components/gallery-component"

const GalleryPage = () => (
    <>
        <SEO title="Gallery" />
        <GalleryComponent />
    </>
)

export default GalleryPage






